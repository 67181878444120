import { ErrorPage } from '@modules/_error';
import { NextPageWithConfig } from '@modules/_app';

const NotFound: NextPageWithConfig = () => {
  return (
    <>
      <ErrorPage statusCode={404} withHeader />
    </>
  );
};

NotFound.config = {
  offerHidden: true
};

export default NotFound;
